import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/app/node_modules/.pnpm/@auth0+nextjs-auth0@3.5.0_next@14.2.3_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"variable\":\"--font-roboto\"}],\"variableName\":\"RobotoFont\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/Headers/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/Providers.tsx");
